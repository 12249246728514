.upload-field-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%; /* Make it full width */
  height: 300px; /* Default height */
  overflow: hidden;
  background-color: #cfd8dc;
}

.uploaded-image {
  display: inline-block;
  object-fit: cover;
  object-position: center;
  width: 100%; /* Make the image responsive */
  height: 300px; /* Make the image responsive */
}
